<template>
  <section>
    <div>
      <div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div>
            <b-form-input
                v-model="searchFilter"
                type="search"
                placeholder="Search"
            >

            </b-form-input>
          </div>
          <div>
            <b-button
                :to="{ name: 'admin-new-user-role'}"
                variant="primary"
            >
              New User Role
            </b-button>
          </div>
        </div>
      </div>

      <div>
        <div v-for="(role, i) in filteredUserRoles" :key="i" class="">
          <b-card class="mb-1">
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div>
                    <h4>{{ getValueFromSource(role, 'title') }}</h4>
                  </div>
                  <div>
                    <p>{{ getValueFromSource(role, 'description') }}</p>
                  </div>
                </div>
                <div>
                  <b-dropdown
                      variant="link"
                      no-caret
                      dropleft
                      :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                        @click="togglePolicies(role)"
                    >
                      <feather-icon icon="FileTextIcon" />
                      <span class="align-middle ml-50">Toggle Policies</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        :to="{
                          name: 'admin-update-user-role',
                          params: { id: role.id },
                        }"
                    >
                      <feather-icon icon="FileTextIcon" />
                      <span class="align-middle ml-50">Update</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteUserRole(role.id)">
                      <feather-icon class="text-danger" icon="TrashIcon" />
                      <span class="align-middle ml-50 text-danger">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>

                </div>
              </div>
            </div>

          </b-card>
        </div>
      </div>

    </div>
    <div>
      <b-modal ref="policy-details" hide-footer centered hide-header-close>
        <div>
          <div class="mb-2">
            <h2>Policies </h2>
          </div>
          <div v-for="(policy, n) in policies" :key="n" class="mb-50">
            <b-button
                :to="{ name: 'admin-view-policies', params: { id: getValueFromSource(policy, 'id') }}"
                pill
                variant="primary"
            >
              {{ policy.name }}
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import { BCard, BButton, BDropdown, BFormInput, BDropdownItem, BDropdownGroup, } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { debounce, get } from "lodash";


const watchHandler = {
  handler() {
    this.debounceFetchAllData();
  },
  immediate: false,
};

export default {
  name: "ListUserRoles",
  components: {
    BCard,
    BButton,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BDropdownGroup
  },
  data(){
    return {
      loading: false,
      searchFilter: null,
      userRoles: [],
      policies: [],

      debounceFetchAllData: () => {},
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    filteredUserRoles() {
      return this.userRoles.filter(this.canView)
    }
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
        this.debounceFetchAllData();
      },
      immediate: false,
    },
    currentPage: watchHandler,
    searchFilter: watchHandler,
  },
  async created() {
    this.debounceFetchAllData = debounce(this.fetchUserRoles, 500);

    const {
      status,
      page = 1,
      search = "",
      limit = this.perPage
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.statusFilter = status;
    this.perPage = +limit;
  },
  methods: {
    async fetchUserRoles() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.searchFilter,
        }
        this.$router.push({ query }).catch(() => {});

        const response = await useJwt.adminService.getUserRoles(query);
        const { data, pagination } = response.data;
        
        this.totalRecords = pagination.totalRecords;
        this.userRoles = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteUserRole(user_role_id) {
      try {
        this.loading = true;
        await this.useJwt().adminService.deleteUserRole(user_role_id);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `User role updated successfully`
          },
        });
        await this.fetchUserRoles();
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    togglePolicies(role) {
      this.policies = this.getValueFromSource(role, 'policies', []);
      this.$refs['policy-details'].toggle('#toggle-btn')
    }
  }
}
</script>

<style scoped>

</style>
